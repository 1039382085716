<template>
    <div class="invest_graph" :class="useBackground && 'useBackground'">
        <div class="invest_graph__left">
            <apexchart 
                type="donut" 
                :options="chartOptions" 
                class="chart"
                :width="chartSize.width"
                :height="chartSize.height"
                :series="series" />
        </div>
        <div class="invest_graph__right">
            <div>
                <div class="graph_label">
                    Стоимость проекта
                </div>
                <div class="graph_value">
                    {{ allPrice }} млн. тенге
                </div>
                <div class="graph_list">
                    <div class="graph_list__item">
                        <div class="list_marker" style="background: #1c65c0;" />
                        <div>
                            <div>
                                Собственные средства
                            </div>
                            <div>
                                {{ ownFunds }} млн. тенге
                            </div>
                        </div>
                    </div>
                    <div class="graph_list__item">
                        <div class="list_marker" style="background: #f47c5f;"  />
                        <div>
                            <div>
                                Заемные средства
                            </div>
                            <div>
                                {{ borrowedFunds }} млн. тенге
                            </div>
                        </div>
                    </div>
                    <div class="graph_list__item">
                        <div class="list_marker" style="background: #55bcc8;"  />
                        <div>
                            <div>
                                Банки второго уровня
                            </div>
                            <div>
                                {{ bankFunds }} млн. тенге
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        graphColor: {
            type: String,
            default: '#f9f9f9'
        },
        useBackground: {
            type: Boolean,
            default: true
        }
    },
    components: {
        apexchart: VueApexCharts
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        chartSize() {
            if(this.windowWidth < 1800) {
                if(this.windowWidth < 1650) {
                    if(this.windowWidth < 768) {
                        return {
                            width: '230px',
                            height: '230px'
                        }
                    } else {
                        return {
                            width: '180px',
                            height: '180px'
                        }
                    }
                } else {
                    return {
                        width: '200px',
                        height: '200px'
                    }
                }
            } else {
                return {
                    width: '230px',
                    height: '230px'
                }
            }
        },
        allPrice() {
            return Number(Math.round(this.item.funds))
        },
        ownFunds() {
            return Number(Math.round(this.item.own_funds))
        },
        borrowedFunds() {
            return Number(Math.round(this.item.borrowed_funds))
        },
        bankFunds() {
            return Number(Math.round(this.item.bank_funds))
        },
        allPriceStat() {
            return this.allPrice - this.ownFunds - this.borrowedFunds - this.bankFunds
        },
        series() {
            return [this.borrowedFunds, this.ownFunds, this.bankFunds, this.allPriceStat]
        },
        percent() {
            return parseInt(((this.ownFunds + this.borrowedFunds + this.bankFunds) * 100/this.allPrice).toFixed(2))
        }
    },
    data() {
        return {
            tab: 1,
            chartOptions: {
                stroke: {
                    width: 0
                },
                legend: {
                    show: false
                },
                colors: [
                    '#f47d60',
                    '#1c65c0',
                    '#55bcc8',
                    this.graphColor
                ],
                dataLabels: {
                    enabled: false,
                    value: {
                        formatter(value) {
                            // eslint-disable-next-line radix
                            return `${parseInt(value)}%`
                        }
                    },
                    style: {
                        fontSize: '10px',
                        colors: ['#333']
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                chart: {
                    foreColor: '#000'
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        startAngle: 1,
                        donut: {
                            size:'90%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '2rem',
                                    color: '#000',
                                    offsetY: 20
                                },
                                value: {
                                    fontSize: '1.8rem',
                                    fontWeight: 300,
                                    offsetY: -14,
                                    formatter: (value) => {
                                        // eslint-disable-next-line radix
                                        return `${parseInt((parseInt(value) * 100/this.allPrice).toFixed(2))}%`
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    label: 'собрано средств',
                                    formatter: () => `${this.percent}%`
                                },
                            }
                        }
                    },
                },
                labels: [
                    'Заемные средства', 
                    'Собственные средства', 
                    'Банки второго уровня', 
                    ''
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.invest_graph{
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 160px 1fr;
    }
    @media (min-width: 1600px) {
        grid-template-columns: 160px 1fr;
    }
    @media (min-width: 1800px) {
        grid-template-columns: 200px 1fr;
    }
    &__right{
        display: flex;
        justify-content: flex-start;
    }
    &.useBackground{
        &::v-deep{
            circle{
                fill: #f9f9f9;
            }
        }
    }
    &::v-deep{
        .chart{
            margin-left: -20px;
        }
    }
    .graph_list{
        margin-top: 10px;
        font-size: 13px;
        &__item{
            display: flex;
            align-items: baseline;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            .list_marker{
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #f9f9f9;
                margin-right: 10px;
            }
        }
    }
    .graph_label{
        color: #000000;
        opacity: 0.6;
        font-size: 13px;
        margin-bottom: 6px;
    }
    .graph_value{
        color: #000000;
        font-size: 24px;
        line-height: 24px;
    }
}
</style>